.back{
font-size: larger;
width: 100%;
background:rgb(28, 28, 28);
color: azure;
border: rgb(55, 55, 55) solid 0.5px;
height: 4rem;
display: flex;
justify-content: center;
align-items: center;

}
.ChartTitle{
    margin-top:"20px";
display: flex;
justify-content: center;
align-items: center;
width:"100%";

}